/* ==========================================================================
   #CONTACT PAGE
   ========================================================================== */

.c-contact {
}

.page_contact {
  footer {
    margin-top: 70px;

    @include bp_down(medium) {
      margin-top: 50px;
    }
  }
}

.c-contact__map {
  padding-top: 20px;

  iframe {
    height: 509px;
    width: 100%;
    //filter: brightness(100%) contrast(100%) saturate(0%) blur(0px) hue-rotate(0deg);

    @include bp_down(medium) {
      height: 350px;
    }
  }
}

.c-contact__items {
  padding: 15px 0px 0px 0px;
}

.c-contact-item__heading {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.5);
  margin: 0px;
  padding: 25px 0px 0px 0px;
}

.c-contact-item__content {
  padding: 15px 0px 0px 0px;
  font-size: 18px;
  line-height: 28px;
}

#contact_form {
  border-top: 1px solid #e6e6e6;
  padding-top: 50px;
  margin-top: 60px;

  @include bp_down(medium) {
    margin-top: 50px;
    padding-top: 45px;
  }

  h5 {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 30px 0px;
  }

  input,
  textarea {
    margin-bottom: 20px;
    min-height: 46px;
  }
}