/* ==========================================================================
   #IMAGES
   ========================================================================== */

img {
  max-width: 100%;
}

img[width],
img[height] {
  max-width: none;
}
