/* ==========================================================================
   #LOGO
   ========================================================================== */

.e-logo {
  padding: 25px 0px 27px 0px;

  .cls-1,
  h3 {
    font-size: 14px;
    opacity: 0.85;
    font-family: "Work Sans", sans-serif;
    font-weight: 600;
    color: #000;
    padding: 0px;
    margin: 0px;
    display: block;
  }

  h3 {
    font-size: 40px;
  }
}

@include bp_down(xlarge) {
  .e-logo {
    padding: 0px 0px 0px 20px;

    img,
    svg {
      max-height: 40px;
      display: block;
    }
  }
}