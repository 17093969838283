/* ==========================================================================
   #ICONS
   ========================================================================== */

.e-icons {
  font-family: simple-line-icons;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.e-icons--arrow-right:before {
  content: "\e606";
}

.e-icons--arrow-left:before {
  content: "\e605";
}

.e-icons--icon-heart:before {
  content: "\e08a";
}

.e-icons--icon-menu:before {
  content: "\e601";
}

.e-icons--icon-handbag:before {
  content: "\e035";
}

.e-icons--icon-user:before {
  content: "\e005";
}

.e-icons--icon-magnifier:before {
  content: "\e090";
}

.e-icons--arrow-down:before {
  content: "\e604";
}

.e-icons--grid:before {
  content: "\e06a";
}

.e-icons--list:before {
  content: "\e067";
}

.e-icons--equalizer:before {
  content: "\e06c";
}

.e-icons--heart:before {
  content: "\e08a";
}

.e-icons--trash:before {
  content: "\e054";
}

.e-icons--icon-home:before {
  content: "\e069";
}