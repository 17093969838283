/* ==========================================================================
   #CLEARFIX
   ========================================================================== */

.u-clearfix {
  height: 1px;
  overflow: hidden;

  &::after {
    content: '' !important;
    display: block !important;
    clear: both !important;
  }
}
