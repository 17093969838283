/* ==========================================================================
   #FAQ
   ========================================================================== */

.c-faq {
  padding-top: 50px;

  #contact_form {
    padding: 0px;
    margin: 0px;
    border: none;
  }
}

@include bp_down(medium) {
  .c-faq {
    padding-top: 30px;
  }
}

.c-faq__question {
  background-color: #FAFAFA;
  border: 1px solid #E5E5E5;
  padding: 20px 20px 20px 20px;
  margin-bottom: 20px;
}

.c-question__number {
  width: 40px;
  height: 40px;
  background: #000;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  margin: 0px;
  padding: 0px;
  position: absolute;
}

.c-question__question {
  padding: 13px 0px 0px 50px;
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

.c-question__answer {
  padding: 22px 0px 0px 50px;
  margin: 0px;
  color: #888888;
}