/* ==========================================================================
   #TABLES
   ========================================================================== */

table {
  width: 100%;
  text-align: left;
}

table, .table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 23px;
}

table > thead > tr > th, table > thead > tr > td, table > tbody > tr > th, table > tbody > tr > td, table > tfoot > tr > th, table > tfoot > tr > td, .table > thead > tr > th, .table > thead > tr > td, .table > tbody > tr > th, .table > tbody > tr > td, .table > tfoot > tr > th, .table > tfoot > tr > td {
  padding: 0.75rem;
  line-height: 1.5;
  vertical-align: top;
  border-top: 1px solid rgb(232, 232, 232);
}

table > thead > tr > th, .table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid rgb(232, 232, 232);
}

table > caption + thead > tr:first-child > th, table > caption + thead > tr:first-child > td, table > colgroup + thead > tr:first-child > th, table > colgroup + thead > tr:first-child > td, table > thead:first-child > tr:first-child > th, table > thead:first-child > tr:first-child > td, .table > caption + thead > tr:first-child > th, .table > caption + thead > tr:first-child > td, .table > colgroup + thead > tr:first-child > th, .table > colgroup + thead > tr:first-child > td, .table > thead:first-child > tr:first-child > th, .table > thead:first-child > tr:first-child > td {
  border-top: 0px;
}

table > tbody + tbody, .table > tbody + tbody {
  border-top: 2px solid rgb(232, 232, 232);
}

table .table, .table .table {
  background-color: rgb(255, 255, 255);
}

.table-condensed > thead > tr > th, .table-condensed > thead > tr > td, .table-condensed > tbody > tr > th, .table-condensed > tbody > tr > td, .table-condensed > tfoot > tr > th, .table-condensed > tfoot > tr > td {
  padding: 8px;
}

table, .table-bordered {
  border: 1px solid rgb(232, 232, 232);
}

table > thead > tr > th, table > thead > tr > td, table > tbody > tr > th, table > tbody > tr > td, table > tfoot > tr > th, table > tfoot > tr > td, .table-bordered > thead > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > th, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > th, .table-bordered > tfoot > tr > td {
  border: 1px solid rgb(232, 232, 232);
}

.table-striped > tbody > tr:nth-child(2n+1) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
  background-color: rgb(249, 249, 249);
}

.table-hover > tbody > tr:hover > td, .table-hover > tbody > tr:hover > th {
  background-color: rgb(245, 245, 245);
}

table col[class*="col-"] {
  position: static;
  float: none;
  display: table-column;
}

table td[class*="col-"], table th[class*="col-"] {
  position: static;
  float: none;
  display: table-cell;
}

.table-active, .table-active > th, .table-active > td {
  background-color: rgb(245, 245, 245);
}

.table-hover .table-active:hover {
  background-color: rgb(232, 232, 232);
}

.table-hover .table-active:hover > td, .table-hover .table-active:hover > th {
  background-color: rgb(232, 232, 232);
}

.table-success, .table-success > th, .table-success > td {
  background-color: rgb(223, 240, 216);
}

.table-hover .table-success:hover {
  background-color: rgb(208, 233, 198);
}

.table-hover .table-success:hover > td, .table-hover .table-success:hover > th {
  background-color: rgb(208, 233, 198);
}

.table-info, .table-info > th, .table-info > td {
  background-color: rgb(217, 237, 247);
}

.table-hover .table-info:hover {
  background-color: rgb(196, 227, 243);
}

.table-hover .table-info:hover > td, .table-hover .table-info:hover > th {
  background-color: rgb(196, 227, 243);
}

.table-warning, .table-warning > th, .table-warning > td {
  background-color: rgb(252, 248, 227);
}

.table-hover .table-warning:hover {
  background-color: rgb(250, 242, 204);
}

.table-hover .table-warning:hover > td, .table-hover .table-warning:hover > th {
  background-color: rgb(250, 242, 204);
}

.table-danger, .table-danger > th, .table-danger > td {
  background-color: rgb(242, 222, 222);
}

.table-hover .table-danger:hover {
  background-color: rgb(235, 204, 204);
}

.table-hover .table-danger:hover > td, .table-hover .table-danger:hover > th {
  background-color: rgb(235, 204, 204);
}

@media screen and (max-width: 767px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 10px;
    overflow: auto hidden;
  }
  .table-responsive > table {
    margin: 0px;
  }
  .table-responsive > .table > thead > tr > th, .table-responsive > .table > thead > tr > td, .table-responsive > .table > tbody > tr > th, .table-responsive > .table > tbody > tr > td, .table-responsive > .table > tfoot > tr > th, .table-responsive > .table > tfoot > tr > td {
    white-space: nowrap;
  }
  .table-responsive > .table-bordered {
    border: 0px;
  }
  .table-responsive > .table-bordered > thead > tr > th:first-child, .table-responsive > .table-bordered > thead > tr > td:first-child, .table-responsive > .table-bordered > tbody > tr > th:first-child, .table-responsive > .table-bordered > tbody > tr > td:first-child, .table-responsive > .table-bordered > tfoot > tr > th:first-child, .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0px;
  }
  .table-responsive > .table-bordered > thead > tr > th:last-child, .table-responsive > .table-bordered > thead > tr > td:last-child, .table-responsive > .table-bordered > tbody > tr > th:last-child, .table-responsive > .table-bordered > tbody > tr > td:last-child, .table-responsive > .table-bordered > tfoot > tr > th:last-child, .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0px;
  }
  .table-responsive > .table-bordered > tbody > tr:last-child > th, .table-responsive > .table-bordered > tbody > tr:last-child > td, .table-responsive > .table-bordered > tfoot > tr:last-child > th, .table-responsive > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0px;
  }
}

table.shop_attributes {
  border: 1px solid rgb(221, 221, 221);
}

table.shop_attributes th, table.shop_attributes td {
  line-height: 43px;
  border-style: solid;
}

table.shop_attributes td {
  padding: 0px 30px;
  border-color: rgb(221, 221, 221);
  border-left-width: 1px;
  border-left-style: solid;
  width: 50%;
  font-style: normal;
  color: rgb(85, 85, 85);
  vertical-align: middle;
}

table.shop_attributes td p {
  padding: 0px;
}

table.shop_attributes th {
  padding: 0px 30px;
  vertical-align: middle;
  border-color: rgb(221, 221, 221);
  color: rgb(0, 0, 0);
  font-weight: 600;
}

table p {
  padding: 0px;
  margin: 0px;
}

table.shop_attributes tr:nth-child(even) td, table.shop_attributes tr:nth-child(even) th {
  background: rgba(0, 0, 0, .025);
}

table.shop_attributes th {
  padding: 0px 30px;
  vertical-align: middle;
  border-color: rgb(221, 221, 221);
  color: rgb(0, 0, 0);
  font-weight: 600;
}

table {
  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;

    li {
      padding: 0px;
      margin: 0px;
    }
  }
}