/* ==========================================================================
   #RECENTLY VIEWED
   ========================================================================== */

.c-header-recently-viewed {
  padding: 0px 40px 0px 10px;
}

.c-header-recently-viewed__heading {
  cursor: pointer;
  padding: 0px;
  margin: 0px;

  .e-icons {
    font-size: 10px;
  }
}

.c-header-recently-viewed__content {
  opacity: 0;
  visibility: hidden;
  background: #fff;
  position: absolute;
  left: 50px;
  right: 50px;
  z-index: 12;
  padding: 0px 30px 30px 30px;
  pointer-events: none;
  transition: $global-transition;
}

.c-header-recently-viewed__content--active {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.c-header-recently-viewed__empty {
  padding: 50px 0px 35px 0px;
  text-align: center;
}

#recently-viewed-products {
  margin: 0px -15px;
  overflow: hidden;
  position: relative;
}

.c-recently-viewed__product {
  width: 100%;
  min-width: 14.28%;
  max-width: 14.28%;
  padding: 0px 15px;
  padding-top: 44px;

  img {
    display: block;
    width: 100%;
  }
}