/* ==========================================================================
   #BLOG
   ========================================================================== */

.c-blog-post {
  margin: 50px 0px 0px 0px;
  position: relative;
}

@include bp_down(medium) {
  .c-blog-post {
    margin: 30px 0px 0px 0px;
  }
}

.c-blog-post__tags {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 2;
  list-style: none;
  padding: 0px;
  margin: 0px;

  li {
    display: inline-block;
    vertical-align: top;
    background: $color-white;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    color: $color-black;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    padding: 0px 10px;
    margin: 0px 2px 0px 0px;
  }
}

.c-blog-post__image {
  position: relative;
  overflow: hidden;

  &:hover {
    a {
      img {
        transform: scale(1.05);
        -webkit-transform: scale(1.05);
        -moz-transform: scale(1.05);
      }
    }
  }

  a {
    display: block;

    img {
      display: block;
      width: 100%;
      height: 280px;
      object-fit: cover;
      transition: $global-transition;
    }
  }
}

@include bp_down(large) {
  .c-blog-post__image {
    a {
      img {
        height: 230px;
      }
    }
  }
}

@include bp_down(medium) {
  .c-blog-post__image {
    a {
      img {
        height: 187px;
      }
    }
  }
}

.c-blog-post__title {
  line-height: 1.3;
  padding: 20px 0px 0px 0px;
  margin: 0px;
}

@include bp_down(medium) {
  .c-blog-post__title {
    font-size: 18px;
    padding: 15px 0px 0px 0px;
  }
}

.c-blog-post__date {
  padding: 10px 0px 0px 0px;
  margin: 0px;
}

@include bp_down(medium) {
  .c-blog-post__date {
    font-size: 13px;
    line-height: 19px;
  }
}

/**
 * Article content */
.c-article__image {
  padding: 0px 0px 60px 0px;

  @include bp_down(medium) {
    padding-bottom: 40px;
  }

  img {
    display: block;
    width: 100%;
  }
}