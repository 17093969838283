// ==========================================================================
// #GLOBAL
// ==========================================================================

$global-radius: 3px !default;
$global-transition: all 300ms ease-in-out !default;

/* Fonts
   ========================================================================== */

$font-sans-serif: 'Work Sans', 'Arial', sans-serif !default;

/* Breakpoints
   ========================================================================== */

$breakpoints: (
        small: 480px,
        medium: 768px,
        large: 1024px,
        xlarge: 1280px,
        full: 1600px,
);

/* Colors
   ========================================================================== */

$color-white: #fff;
$color-black: rgba(0, 0, 0, 0.85);
$color-theme: #3D4392;
$color-black-light: rgba(0, 0, 0, 0.65);
$color-light-grey: #999;
$color-medium-grey: #666;
$color-dark-grey: #333;

$color-black2: #000;
$color-black3: #242424;
$color-very-light-grey: #ccc;
$color-light-grey2: #B3B3B3;
$color-light-grey3: #E3E3E3;
$color-grey: #808080;
$color-grey2: #F2F2F2;
$color-grey3: #e6e6e6;
$color-grey4: #c4c4c4;
$color-grey5: #f3f3f3;
$color-light-pink: #FFEEEF;
$color-red: #dd2831;
$color-white-transparent: rgba(255, 255, 255, 0.1);

// Text
$color-bg: $color-white;
$color-text: $color-theme;

// Links
$color-link: $color-theme;
$color-hover: $color-black;

// Borders
$color-border: $color-theme;

// Fills
$color-fill: $color-theme;