/* ==========================================================================
   #PRODUCT PAGE
   ========================================================================== */

.c-product-page {
  padding: 30px 0px 0px 0px;
}

@include bp_down(medium) {
  .c-product-page__o-flex {
    flex-wrap: wrap;
  }
}

/**
 * Product left
 */
.c-product-page__left {
  width: 675px;
  padding-right: 70px;
}

@include bp_down(xlarge) {
  .c-product-page__left {
    padding-right: 20px;
  }
}

@include bp_down(medium) {
  .c-product-page__left {
    width: 100%;
    padding-right: 0px;
  }
}

/**
 * Thumbnails
 */
@include bp_down(medium) {
  .c-product-images {
    flex-wrap: wrap;
  }
}

.c-product-images__left {
  min-width: 105px;
  max-width: 105px;
  padding-right: 30px;
}

@include bp_down(large) {
  .c-product-images__left {
    min-width: 80px;
    max-width: 80px;
    padding-right: 20px;
  }
}

@include bp_down(medium) {
  .c-product-images__left {
    width: 100%;
    min-width: 1px;
    max-width: 100%;
    order: 2;
    padding: 0px;
  }
}

ul.c-product-images__thumbnails {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

@include bp_down(medium) {
  ul.c-product-images__thumbnails {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -5px;
    width: 100%;
  }
}

li.c-product-images__thumbnail {
  padding: 0px;
  margin: 0px 0px 10px 0px;
  cursor: pointer;

  &.c-product-images__thumbnail--active {
    img {
      border: 1px solid $color-black;
    }
  }

  img {
    width: 100%;
    display: block;
  }
}

@include bp_down(medium) {
  li.c-product-images__thumbnail {
    width: 20%;
    padding: 10px 5px 0px 5px;
  }
}

/**
 * Main image
 */
.c-product-images__image {
  height: 0px;
  overflow: hidden;
  opacity: 0;
}

.c-product-images__image--active {
  height: auto;
  visibility: visible;
  opacity: 1;
}

.c-product-images__right {
  width: 100%;
  position: relative;

  img {
    display: block;
    width: 100%;
  }
}

/**
 * Wishlist
 */
.c-product-images__wishlist {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 2;
  font-size: 24px;
  cursor: pointer;

  &.c-product-images__wishlist--active {
    color: #dd2831;
  }
}

/**
 * Product right
 */
.c-product-page__right {
  width: 555px;
  padding-left: 30px;
}

@include bp_down(medium) {
  .c-product-page__right {
    width: 100%;
    padding: 40px 0px 0px 0px;
  }
}

.c-product-page__product-title {
  font-size: 30px;
  line-height: 1.3;
  padding: 0px 0px 0px 0px;
  margin: 0px;
}

@include bp_down(medium) {
  .c-product-page__product-title {
    font-size: 24px;
  }
}

/** 
 * Product info 
 */
.c-product-page__info {
  list-style: none;
  padding: 27px 0px 0px 0px;
  margin: 0px;
}

li.c-info__item {
  display: flex;
  padding: 3px 0px 0px 0px;
  margin: 0px;
  font-size: 13px;
  line-height: 19px;
}

p.c-info__heading {
  padding: 0px;
  margin: 0px 10px 0px 0px;
  min-width: 90px;
  max-width: 90px;
}

span.c-info__content {
  font-weight: 600;
}

/**
 * Product price
 */
.c-product-page__price {
  padding: 10px 0px 0px 0px;
  font-size: 30px;
  line-height: 1.5;
  font-weight: 600;
}

.c-product-page__price--new {
  color: $color-red;
}

.c-product-page__price--standard {
  color: rgba(0, 0, 0, 0.85);
}

.c-product-page__price--old {
  font-size: 15px;
  font-weight: 300;
  text-decoration: line-through;
  padding-left: 3px;
  color: $color-light-grey;
}

/** 
 * Product description
 */
.c-product-page__description {
  padding: 15px 0px 0px 0px;
}

/**
 * Product form
 */
.c-product-page__form {
  padding: 0px 0px 0px 0px;
  margin: 0px;
}

/**
 * Size option
 */
.c-option {
  padding: 20px 0px 0px 0px;
}

.c-option__size {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0px;
  margin: 0px;
}

li.c-option-size {
  padding: 0px;
  margin: 5px 10px 0px 0px;
  position: relative;

  p {
    cursor: pointer;
    display: block;
    border: 1px solid $color-grey3;
    font-size: 13px;
    line-height: 17px;
    font-weight: 500;
    padding: 5px 15px 4px 15px;
    margin: 0px;
  }

  &.c-option-size--active {
    p {
      border: 1px solid #000;
    }
  }
}

/**
 * Add to cart
 */
.c-product-page__add-to-cart {
  padding: 30px 0px 0px 0px;
}

.c-quantity {
  width: 128px;
  min-width: 128px;
}

.c-quantity__wrapper-input {
  position: relative;
  margin-top: 5px;
}

.c-quantity__down {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  padding: 10px;
}

.c-quantity__input {
  display: block;
  width: 100%;
  height: 46px;
  text-align: center;
  outline: none;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@include bp_down(medium) {
  .c-quantity__input {
    height: 40px;
  }
}

.c-quantity__up {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  padding: 10px;
}

.c-quantity__title {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.c-btn--add-to-cart {
  width: 100%;
  display: block;
  border: none;
  background: $color-theme !important;
  color: rgba(255, 255, 255, 0.85) !important;
  cursor: pointer;
  outline: none;
  margin: 25px 0px auto 12px;
  display: none;
}

.c-btn--active {
  display: block;
}

.c-btn--loading {
  position: relative;
  color: rgba(255, 255, 255, 0) !important;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    transform: translate(-50%, -50%) !important;
    -webkit-transform: translate(-50%, -50%) !important;
    -moz-transform: translate(-50%, -50%) !important;
    background: url(../loader3.svg) center no-repeat;
    background-size: cover;
  }
}

/**
 * Buy it now
 */
.c-btn--buy-it-now {
  width: 100%;
  display: block;
  cursor: pointer;
  outline: none;
  margin-top: 12px;
  background: none;
}

.shopify-payment-button {
  button[aria-disabled='true'] {
    display: none !important;
  }

  button {
    margin: 12px 0px 0px 0px !important;
    border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    background: none !important;
    border: 1px solid #000 !important;
    color: #000 !important;
    text-transform: uppercase !important;
    padding: 15px 40px 15px 40px !important;
  }
}

@include bp_down(medium) {
  .shopify-payment-button button {
    padding: 12px 30px !important;
  }
}

/**
 * Social share
 */
.c-share {
  padding: 25px 0px 0px 0px;

  .st-btn {
    margin-right: 10px !important;
  }
}

/**
 * Product tabs
 */

.c-product-tab-item {
  display: none !important;
  margin-top: 50px !important;
}

@include bp_down(medium) {
  .c-product-tab-item {
    margin-top: 35px !important;
  }
}

.c-product-tab-item--active {
  display: block !important;
}

.c-product-tabs {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.c-product-tabs__item {
  padding: 0px;
  margin: 0px;

  a {
    border: 1px solid rgb(232, 232, 232);
    position: relative;
    display: block;
    margin-right: -1px;
    font-size: 14px;
    line-height: 42px;
    padding: 0px 16px;
    background: rgb(245, 245, 245);
    text-transform: uppercase;
    cursor: pointer;
  }
}

.c-product-tabs__item--active {
  a {
    background: #fff;
  }
}

/**
 * Notification
 */
.notification-container {
  top: auto !important;
  bottom: 20px !important;
}

.box {
  float: left;
  height: 20px;
  width: 20px;
  margin-bottom: 15px;
  border: 1px solid black;
  clear: both;
  margin-right: 15px;
}