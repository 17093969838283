/* ==========================================================================
   #NAVIGATION
   ========================================================================== */

.c-header-nav {
  margin: 0px 0px 0px 3.6%;
  display: flex;
  width: 50%;
  position: relative;
  padding: 0px;
  list-style: none;
}

@include bp_down(xlarge) {
  .c-header-nav {
    display: none;
  }
}

.c-header__nav-item {
  padding: 0px;
  margin: 0px;
}

.c-header__nav-link {
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  display: block;
  padding: 42px 18px;
  margin: 0px;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;

  &:hover {
    color: rgba(0, 0, 0, 0.85);
  }
}

.c-header__nav-link--active {
  color: rgba(0, 0, 0, 0.85);
}

.c-header__sub-menu {
  position: absolute;
  top: 100%;
  background: #fff;
  width: 250px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 400ms ease-in-out;

  &.c-header__sub-menu--mega-menu {
    left: 0;
    right: 0;
    width: 100%;
    padding: 15px 18px 40px 18px;
  }

  &.c-header__sub-menu--two-columns {
    left: auto;
    width: 600px;
    right: auto;
  }
}

h3.c-menu__heading + ul.c-menu__sub-menu {
  padding: 0px;

  li.c-menu__item {
    padding: 5px 0px;

    a {
      padding: 0px;
    }
  }
}

ul.c-menu__sub-menu {
  padding: 15px 0px;
  margin: 0px;
  list-style: none;

  li.c-menu__item {
    padding: 0px;
    margin: 0px;

    a {
      color: rgba(0, 0, 0, 0.5);
      display: block;
      padding: 5px 18px;
      font-size: 14px;
      line-height: 2;

      &:hover {
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
}

.c-header__nav-item:hover .c-header__sub-menu {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.c-menu__banner {
  display: none;
  padding: 5px 0px 0px 0px;
}

.c-header__nav-item:hover .c-menu__banner {
  display: block;
}

.c-menu__banner-image {
  display: block;
  width: 100%;
}

.c-menu__heading {
  padding: 0 0 10px 0;
  margin: 0px;
  font-size: 16px;
  line-height: 24px;
  position: relative;
}

/**
 * Mobile Menu
 */
.slide-menu {
  background: #fff;
  width: 100%;

  .slide-menu__control--close {
    background: none;
    border: none;
    padding: 20px 15px 15px 15px;
    margin: 0px;
    cursor: pointer;
    position: relative;
    z-index: 9;
    display: block;
    width: 100%;
    text-align: right;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 12;
    }

    button {
      display: block;
      padding: 0px;
      margin: 0px 0px 0px auto;
      background: none;
      border: none;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  a[data-action='back'] {
    .e-icons {
      line-height: 38px;
      display: block;
      float: left;
      padding-right: 15px;
    }
  }

  ul {
    padding: 0px;
    margin: 0px;
    list-style: none;

    li {
      padding: 0px;
      margin: 0px;
      padding: 0px 15px;

      a {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: inherit;
        display: block;
        padding: 4px 0px;
        margin: 0;
        line-height: 38px;
        text-transform: uppercase;
        border-bottom: 1px solid #e8e8e8;

        .slide-menu__decorator {
          display: block;
          float: right;
          padding-left: 15px;

          span {
            line-height: 38px;
          }
        }
      }
    }
  }
}

.avatar {
  vertical-align: middle;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}