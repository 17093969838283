/* ==========================================================================
   #NAV SIDEBAR
   ========================================================================== */

.c-header-nav-sidebar {
  padding: 0px 0px 0px 24px;

  i {
    line-height: 44px;
    font-size: 24px;
    cursor: pointer;
  }
}

.c-header-nav-sidebar__content {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 375px;
  max-width: 90%;
  background: #fff;
  z-index: 99;
  padding-bottom: 100px;
  overflow-y: scroll;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: $global-transition;
}

@include bp_down(medium) {
  .c-header-nav-sidebar__content {
    padding-bottom: 60px;
  }
}

.c-header-nav-sidebar__content--active {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.e-close-background--nav-sidebar {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  display: block !important;
  z-index: 98;
}

.c-nav-sidebar__close {
  position: absolute;
  top: 20px;
  right: 20px;

  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

ul.c-nav-sidebar {
  list-style: none;
  padding: 133px 60px 0px 60px;
  margin: 0px;

  li {
    margin: 21px 0px;
    padding: 0px;

    a {
      text-transform: uppercase;
    }
  }
}

@include bp_down(medium) {
  ul.c-nav-sidebar {
    padding: 60px 30px 0px 30px;
  }
}

ul.c-nav-sidebar__contact {
  list-style: none;
  padding: 100px 60px 0px 60px;
  margin: 0px;

  li {
    padding: 0px;
    margin: 0px;
    color: #888888;
    line-height: 32px;
  }
}

@include bp_down(medium) {
  ul.c-nav-sidebar__contact {
    padding: 40px 30px 0px 30px;
  }
}