/* ==========================================================================
   #ABOUT
   ========================================================================== */

.c-about {
  background: #f5f5f5;
}

.c-about__background {
  background: #fff;
  padding-top: 40px;

  img {
    display: block;
    width: 100%;
  }
}

.c-about__content {
  background: #fff;
  padding: 87px 100px 100px 100px;
  position: relative;
  margin: -87px -20px 0px -20px;
  z-index: 2;
}

@include bp_down(large) {
  .c-about__content {
    margin: -50px -20px 0px -20px;
    padding: 40px 50px 50px 50px;
  }
}

@include bp_down(medium) {
  .c-about__content {
    margin: 0px -20px 0px -20px;
    padding: 30px 20px 30px 20px;
  }
}

.c-block__description + .c-block__heading {
  border-top: 1px solid #e2e2e2;
  margin-top: 55px;
  padding-top: 55px;
}

@include bp_down(medium) {
  .c-block__description + .c-block__heading {
    margin-top: 40px;
    padding-top: 40px;
  }
}

.c-block__description {
  padding-top: 10px;

  iframe {
    display: block;
    width: 100%;
    height: 500px;
    border: none;
    margin: 50px 0px 40px 0px;

    @include bp_down(medium) {
      height: 300px;
    }
  }
}

.c-team-member {
  text-align: center;
  margin-bottom: 40px;

  p {
    padding-top: 17px;

    strong {
      display: block;
    }

    span {
      display: block;
    }
  }
}

.page_about {
  footer {
    margin-top: 0px;
  }
}