/* ==========================================================================
   #BREADCRUMB
   ========================================================================== */

.c-breadcrumb {
  padding: 38px 0;
  margin: 40px 0px 0px 0px;

  h1,
  h2 {
    padding: 0px;
    margin: 0px;
    font-size: 44px;
  }

  nav {
    margin-left: auto;

    a {
      color: rgba(0, 0, 0, 0.5);
    }
  }
}

@include bp_down(large) {
  .c-breadcrumb {
    h1,
    h2 {
      font-size: 36px;
    }
  }
}

@include bp_down(medium) {
  .c-breadcrumb {
    padding: 30px 0px 0px 0px;
    margin: 0px 0px 0px 0px;

    .o-flex {
      flex-wrap: wrap;
    }

    h1,
    h2 {
      width: 100%;
      font-size: 30px;
    }

    nav {
      margin: 0px;
      padding: 10px 0px 0px 0px;
    }
  }
}