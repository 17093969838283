/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  border-top: 1px solid #e8e8e8;
  margin: 160px 0px 0px 0px;
  padding: 0px 0px 61px 0px;
  color: rgba(0, 0, 0, 0.65);

  a {
    color: rgba(0, 0, 0, 0.65);
  }
}

@include bp_down(medium) {
  .c-footer {
    margin: 60px 0px 0px 0px;
    padding: 0px 0px 30px 0px;
  }
}

.c-footer__newsletter {
  max-width: 270px;
  padding: 80px 0px 0px 0px;

  form {
    margin: 0px;
    padding: 0px;
  }
}

@include bp_down(medium) {
  .c-footer__newsletter {
    max-width: 100%;
    padding: 70px 0px 6px 0px;
  }
}

.c-footer__button {
  display: block;
  width: 100%;
  background: #262626;
  border: none;
  margin: 12px 0px 0px 0px;
  padding: 12px 20px 12px 20px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
}

.c-footer__menu,
.c-footer__text {
  padding: 73px 0px 0px 0px;
  list-style: none;
  margin: 0px;

  li {
    padding: 0px;
    margin: 0px;
  }
}

@include bp_down(medium) {
  .c-footer__menu,
  .c-footer__text {
    padding: 30px 0px 0px 0px;
  }
}

.c-copyright {
}

@include bp_down(medium) {
  .c-copyright {
    flex-wrap: wrap;
  }
}

.c-copyright__text {
  padding: 106px 0px 0px 0px;
}

@include bp_down(medium) {
  .c-copyright__text {
    width: 100%;
    order: 4;
    padding: 30px 0px 0px 0px;
  }
}

.c-copyright__switchers {
  margin: 96px 145px 0px auto;
}

@include bp_down(large) {
  .c-copyright__switchers {
    margin-right: 0px;
  }
}

@include bp_down(medium) {
  .c-copyright__switchers {
    margin: 0px;
    width: 100%;
    flex-wrap: wrap;
  }
}

.c-switcher {
  margin: 0px 0px 0px 70px;
  position: relative;
  padding: 10px 0px 0px 0px;
  cursor: pointer;
}

@include bp_down(medium) {
  .c-switcher {
    margin: 20px 0px 0px 0px;
    padding: 10px 0px 0px 0px;
    width: 100%;
  }
}

.c-switcher:hover {
  ul.c-switcher__list {
    opacity: 1;
    visibility: visible;
    transform: none;
    -webkit-transform: none;
    -moz-transform: none;
  }
}

.c-switcher__heading {
  padding: 0px;
  margin: 0px;

  span {
    color: rgba(0, 0, 0, 0.85);
    padding-left: 12px;

    span {
      padding-left: 4px;
      font-size: 12px;
    }
  }
}

ul.c-switcher__list {
  position: absolute;
  bottom: 100%;
  right: 0;
  background: #fff;
  border: 1px solid #e8e8e8;
  opacity: 0;
  list-style: none;
  padding: 0px;
  margin: 0px;
  visibility: hidden;
  transform: translateY(10px);
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  transition: all 0.4s ease-in-out;
}

@include bp_down(medium) {
  ul.c-switcher__list {
    left: 0;
    right: auto;
  }
}

li.c-switcher__item {
  border-top: 1px solid #e8e8e8;
  padding: 0px;
  margin: 0px;

  &:first-child {
    border: none;
  }

  a {
    line-height: 40px;
    padding: 3px 12px;
    display: block;
  }
}