/* ==========================================================================
   #BANNERS
   ========================================================================== */

.c-banners {
  padding: 90px 0px 0px 0px;
}

@include bp_down(medium) {
  .c-banners {
    padding: 0px;
  }
}

.c-banners__left {
}

.c-banners__right {
  padding-top: 127px;
}

@include bp_down(medium) {
  .c-banners__right {
    padding-top: 0px;
  }
}

@include bp(medium) {
  .c-banners__right {
    margin-left: 16.666667%;
  }
}

.c-banner {
  margin: 81px 0px 0px 0px;
}

@include bp_down(medium) {
  .c-banner {
    margin-top: 60px;
  }
}

.c-banner__image {
  display: block;
  position: relative;

  img {
    display: block;
    width: 100%;
  }
}

.c-banner__heading {
  padding: 31px 40px 0px 40px;
  margin: 0px;
}

.c-banner__description {
  padding: 15px 40px 0px 40px;
  margin: 0px;
  color: rgba(0, 0, 0, 0.65);
}

.c-banner__btn {
  margin: 21px 40px 0px 40px !important;
}