/* ==========================================================================
   #ACCOUNT
   ========================================================================== */

.c-header-account {
  padding: 0px 0px 0px 24px;

  .e-icons {
    font-size: 24px;
    line-height: 44px;
    cursor: pointer;
  }
}

/**
 * Page account
 */
.c-account {
  padding: 30px 0px 0px 0px;
}

@include bp_down(medium) {
  .c-account {
    padding-top: 10px;
  }

  .o-flex--acount {
    flex-wrap: wrap;
  }
}

.c-account__left {
  padding-top: 50px;
  width: 270px;
  padding-right: 15px;
}

@include bp_down(medium) {
  .c-account__left {
    width: 100%;
    margin: 0px;
    padding: 40px 0px 0px 0px;
    order: 2;
  }
}

.c-account__my-account {
  font-size: 30px;
}

@include bp_down(medium) {
  .c-account__my-account {
    font-size: 24px;
  }
}

.c-account__welcome-back {
  padding: 15px 0px 0px 0px;
  margin: 0px;
}

.c-account__menu {
  list-style: none;
  padding: 18px 0px;
  margin: 23px 0px 23px 0px;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;

  li {
    padding: 5px 0px;
    margin: 0px;
  }
}

.c-account__right {
  width: 700px;
  margin: 0px auto;
  padding-left: 15px;
  padding-top: 50px;
}

@include bp_down(medium) {
  .c-account__right {
    width: 100%;
    margin: 0px;
    padding: 40px 0px 0px 0px;
  }
}

.c-account__title {
  font-size: 30px;
  padding-bottom: 15px;
}

@include bp_down(medium) {
  .c-account__title {
    font-size: 24px;
  }
}

/**
 * Addresses
 */
.c-addresses__add-new {
  float: right;
  position: relative;
  margin-top: -64px;
}

@include bp_down(medium) {
  .c-addresses__add-new {
    margin-top: -53px;
  }
}

.c-edit-address {
  display: none;
}

.c-new-address {
  display: none;
}

/**
 * Address
 */
.c-address {
  padding: 20px 0px;
}

.c-address__title {
  font-size: 17px;
}

.c-address__info {
  list-style: none;
  padding: 0px;
  margin: 0px;

  li {
    padding: 0px;
    margin: 0px;
  }
}

.c-address__action {
  list-style: none;
  padding: 15px 0px 0px 0px;
  margin: 0px;

  li {
    display: inline-block;
    padding: 0px 10px 0px 0px;
    margin: 0px;
  }
}