/* ==========================================================================
   #LAYOUT
   ========================================================================== */

.o-layout {
  display: block;
  margin: 0px -15px;

  @include bp(medium) {
    display: flex;
  }
}

.o-layout__item {
  flex: 1;
  padding: 0px 15px;
}

.o-layout--2 {
  > .o-layout__item {
    @include bp(medium) {
      width: 50%;
    }
  }
}

.o-layout--3 {
  > .o-layout__item {
    @include bp(medium) {
      width: 33.3333%;
    }
  }
}

.o-layout--4 {
  > .o-layout__item {
    @include bp(medium) {
      width: 25%;
    }
  }
}

.o-layout--custom {
  flex-wrap: wrap;

  > .o-layout__item {
    flex: auto;
  }
}

.o-layout--custom {
  @include bp(medium) {
    > .o-layout__item--1 {
      width: 8.333333%;
      max-width: 8.333333%;
    }
    > .o-layout__item--2 {
      width: 16.666667%;
      max-width: 16.666667%;
    }
    > .o-layout__item--25 {
      width: 20%;
      max-width: 20%;
    }
    > .o-layout__item--3 {
      width: 25%;
      max-width: 25%;
    }
    > .o-layout__item--4 {
      width: 33.333333%;
      max-width: 33.333333%;
    }
    > .o-layout__item--5 {
      width: 41.666667%;
      max-width: 41.666667%;
    }
    > .o-layout__item--6 {
      width: 50%;
      max-width: 50%;
    }
    > .o-layout__item--7 {
      width: 58.333333%;
      max-width: 58.333333%;
    }
    > .o-layout__item--8 {
      width: 66.666667%;
      max-width: 66.666667%;
    }
    > .o-layout__item--9 {
      width: 75%;
      max-width: 75%;
    }
    > .o-layout__item--10 {
      width: 83.333333%;
      max-width: 83.333333%;
    }
    > .o-layout__item--11 {
      width: 91.666667%;
      max-width: 91.666667%;
    }
    > .o-layout__item--12 {
      width: 100%;
      max-width: 100%;
    }
  }
}