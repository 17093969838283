/* ==========================================================================
   #CART
   ========================================================================== */

.c-header-cart {
  padding: 0px 0px 0px 24px;
  position: relative;
}

.c-header-cart__icon {
  display: block;
  position: relative;
  cursor: pointer;

  .e-icons--icon-handbag {
    line-height: 44px;
    font-size: 24px;
  }
}

.c-header-cart__counter {
  position: absolute;
  background: #dd2831;
  color: #fff;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  min-width: 18px;
  padding: 0 2px;
  line-height: 18px;
  font-size: 14px;
  top: 3px;
  right: -9px;
  left: auto;
  font-weight: 400;
  text-align: center;
}

.c-header-cart__content {
  position: absolute;
  top: 100%;
  right: 0;
  background: #fff;
  z-index: 7;
  box-shadow: 0 10px 40px rgba(0, 0, 0, .1);
  -webkit-box-shadow: 0 10px 40px rgba(0, 0, 0, .1);
  -moz-box-shadow: 0 10px 40px rgba(0, 0, 0, .1);
  margin-top: 34px;
  width: 410px;
  padding: 32px 40px 40px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: $global-transition;
}

@include bp_down(xlarge) {
  .c-header-cart__content {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-height: 100vh;
    overflow-y: scroll;
    margin: 0px;
    width: 100%;
    height: 100%;
    padding: 20px;
  }
}

.c-header-cart__content--active {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.c-header-cart__heading {
  h5 {
    margin: 0;
    padding: 0px;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 28px;
    color: #272525;
  }
}

.c-header-cart__close {
  padding: 0px;
  margin: 0px 0px 0px auto;

  svg {
    cursor: pointer;
    display: block;
    margin: 0px;
  }
}

.c-header-cart__empty {
  text-align: center;
  padding: 42px 0px 0px 0px;

  p {
    padding: 0px;
    margin: 0px;
  }
}

.c-btn--start-shopping {
  padding: 12px 20px;
  width: 100%;
  display: block;
  background: $color-bg;
  color: $color-theme !important;
  margin-top: 15px;
}

.c-header-cart__items {
  list-style: none;
  padding: 0px;
  margin: 0px;

  li {
    padding: 20px 0px;
    margin: 0px;
    border-bottom: 1px solid #e5e5e5;
  }
}

.c-cart-product__right {
  padding-left: 20px;

  .variant_title {
    display: block;
  }
}

.c-cart-product__remove {
  margin-left: auto;
  padding-left: 20px;

  a {
    cursor: pointer;
  }
}

.c-header-cart__subtotal {
  padding: 22px 0px 10px 0px;
}

.c-subtotal__left {
  font-weight: normal;
  color: #888;
  text-transform: uppercase;
}

.c-subtotal__right {
  padding-left: 20px;
  margin-left: auto;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;
}

.c-btn--checkout {
  display: block;
  width: 100%;
  margin-top: 12px;
}

.c-btn--view-cart {
  display: block;
  width: 100%;
  margin-top: 12px;
}

.c-header-cart__content > div,
.c-header-cart__content > div > div {
  transition: $global-transition;
}

.is-loading > div {
  opacity: 0.5;
}

/** 
 * Page cart
 */
.c-cart {
  padding-top: 25px;

  .c-header-cart__subtotal {
    max-width: 200px;
    margin-left: auto;
  }
}

.c-cart__buttons {
  text-align: right;
  padding-top: 30px;
}

@include bp_down(medium) {
  .c-cart__buttons {
    padding-top: 20px;
  }
}