/* ==========================================================================
   #STICKY TOP
   ========================================================================== */

.u-sticky-top {
  position: sticky;
  top: 20px;
  margin-bottom: auto;
}

@include bp_down(xlarge) {
  .u-sticky-top {
    top: 70px;
  }
}