/* ==========================================================================
   #COLLETION
   ========================================================================== */

.c-collection {
  &.c-collection--loading {
    .c-filter__loading {
      position: relative;
      z-index: 9;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        top: 180px;
        width: 50px;
        height: 50px;
        margin-left: -25px;
        background: url(../loader2.svg);
        background-size: cover;
      }
    }

    .c-collection__products {
      opacity: 0.5;
    }
  }
}

.c-collection__products {
  transition: opacity 0.4s ease-in-out;

  @include bp_down(large) {
    display: flex;
    margin: 0px -5px;

    > .o-layout__item {
      width: 50%;
      min-width: 50%;
      max-width: 50%;
      padding: 0px 5px;
    }
  }
}

.c-collection__empty {
  padding-top: 30px;
}

/**
 * Categories box
 */
.c-collection__categories {
  margin-top: 40px;
}

@include bp_down(medium) {
  .c-collection__categories {
    margin-top: 30px;
  }
}


.c-categories__heading {
  font-size: 18px;
  line-height: 1.4;
  padding: 0px 0px 16px 0px;
  margin: 0px;
}

.c-categories__list {
  list-style: none;
  padding: 0px;
  margin: 0px;

  li {
    padding: 3px 0px;
    margin: 0px;

    a {
      color: #888;

      &:hover {
        color: #000;
      }
    }
  }
}

/**
 * Filters
 */

.c-collection__filters {
}

.c-filter {
  padding: 40px 0px 0px 0px;
}

@include bp_down(medium) {
  .c-filter {
    padding-top: 30px;
  }
}

.c-filter__heading {
  font-size: 18px;
  line-height: 1.4;
  padding: 0px 0px 16px 0px;
  margin: 0px;
}

.c-filter__content {
  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;

    li {
      padding: 3px 0px;
      margin: 0px;
      color: #888;

      input {
        display: inline-block;
        vertical-align: top;
        width: auto;
        margin: 6px 5px 0px 0px;
      }

      label {
        cursor: pointer;
        display: inline-block;
        vertical-align: top;
      }
    }
  }
}

/**
 * Mobile filters
 */
@include bp_down(medium) {
  .c-collection__mobile-widget {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    overflow-y: scroll;
    background: #fff;
    z-index: 99;
    padding: 0px 20px 30px 20px;
    display: none;
  }

  .c-collection__mobile-widget--active {
    display: block;
  }
}

.c-mobile-widget__close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  display: none;

  svg {
    width: 20px;
    height: 20px;
  }
}

@include bp_down(medium) {
  .c-mobile-widget__close {
    display: block;
  }
}

/**
 * Main filter
 */
.c-collection__main-filter {
  padding: 40px 0px 8px 0px;
  border-bottom: 1px solid #e8e8e8;
}

@include bp_down(medium) {
  .c-collection__main-filter {
    padding-top: 30px;
  }
}

.c-main-filter__open-mobile-widget {
  margin: 0px 15px 0px 0px;
  cursor: pointer;

  span {
    line-height: 40px;
  }
}

@include bp(medium) {
  .c-main-filter__open-mobile-widget {
    display: none;
  }
}

.c-main-filter__sort-by {
  margin: 0px 30px 0px 0px;
  position: relative;
}

.c-sort-by__heading {
  line-height: 40px;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;

  span {
    line-height: 40px;
    padding-left: 4px;
    font-size: 10px;
  }
}

.c-main-filter__sort-by:hover {
  .c-sort-by__dropdown {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
}

.c-sort-by__dropdown {
  position: absolute;
  top: 100%;
  margin-top: 20px;
  left: 0;
  z-index: 9;
  border: 1px solid #ddd;
  background: #fff;
  width: 250px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: $global-transition;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -20px;
    left: 0;
    right: 0;
    height: 20px;
  }

  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;

    li {
      padding: 0px;
      margin: 0px;
      border-top: 1px solid #f5f5f5;

      &:first-child {
        border: none;
      }

      a {
        display: block;
        color: rgba(0, 0, 0, 0.85);
        padding: 0px 15px;
        line-height: 40px;

        &:hover,
        &.active {
          color: #000;
          font-weight: 600;
        }
      }
    }
  }
}

.c-main-filter__views {
  margin-left: auto;

  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;

    li {
      padding: 0px 0px 0px 6px;
      margin: 0px;

      p {
        padding: 0px;
        margin: 0px;
      }

      &.active {
        p {
          border-color: #000;
        }
      }

      a,
      p {
        padding: 0px;
        margin: 0px;
        line-height: 30px;
        color: rgba(0, 0, 0, 0.65);
        border: 1px solid #e8e8e8;
        display: block;
        width: 40px;
        height: 40px;
        background: #fff;
        -webkit-transition: .35s;
        -o-transition: .35s;
        transition: .35s;
        text-align: center;
        cursor: pointer;

        &:hover {
          border-color: #000;
        }

        span {
          line-height: 38px;
          font-size: 20px;

          &.e-icons--grid {
            font-size: 16px;
          }
        }
      }
    }
  }
}

/**
 * Flash sale
 */
.c-collection__flash-sale {
  padding: 20px 0px 30px 0px;

  .c-products__left {
    margin-right: auto;
  }
}

@include bp_down(medium) {
  .c-collection__flash-sale {
    padding-bottom: 0px;
  }
}