/* ==========================================================================
   #FORM GROUP
   ========================================================================== */

.c-form-group {
  margin: 0px 0px 10px 0px;

  input {
    padding: 15px 10px;
  }
}

.c-form-group.o-layout {
  margin-left: -15px;
  margin-right: -15px;
}

.c-radio-inline {
  padding-right: 10px;

  input {
    display: inline-block;
    vertical-align: middle;
    width: auto;
    margin-right: 5px;
  }
}