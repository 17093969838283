/* ==========================================================================
   #WRAPPER
   ========================================================================== */

.o-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 1370px;
  padding-left: 100px;
  padding-right: 100px;
}

.o-wrapper--full-width {
  max-width: 100%;
  padding-left: 50px;
  padding-right: 50px;
}

@include bp-down(xlarge) {
  .o-wrapper {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@include bp-down(medium) {
  .o-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
}