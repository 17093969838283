/* ==========================================================================
   #SLIDER
   ========================================================================== */

.c-slider {
  position: relative;
  height: 950px;
  overflow: hidden;
}

@include bp_down(large) {
  .c-slider {
    height: 730px;
  }
}

@include bp_down(medium) {
  .c-slider {
    height: 600px;
  }
}

.glide__slide {
  overflow: hidden;
  position: relative;
}

.c-slider__item {
  height: 950px;
  position: relative;
}

@include bp_down(large) {
  .c-slider__item {
    height: 730px;
  }
}

@include bp_down(medium) {
  .c-slider__item {
    height: 600px;
  }
}

.c-slider__prev-slider {
  position: absolute;
  top: 50%;
  left: 2.6%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  z-index: 2;
  font-size: 32px;
  cursor: pointer;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
  display: none;
  background: none;
  border: none;
  padding: 0px;
  margin-top: 0px;
  outline: none;
}

@include bp(xlarge) {
  .c-slider__prev-slider {
    display: block;
  }
}

.c-slider__next-slider {
  position: absolute;
  top: 50%;
  right: 2.6%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  z-index: 2;
  font-size: 32px;
  cursor: pointer;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
  display: none;
  background: none;
  border: none;
  padding: 0px;
  margin-top: 0px;
  outline: none;
}

@include bp(xlarge) {
  .c-slider__next-slider {
    display: block;
  }
}

.c-slider__first-image {
  position: absolute;
  top: 70px;
  left: 60%;
  bottom: -50px;
  min-width: 352px;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);

  img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: auto;
    height: 100%;
    object-fit: cover;
    max-width: none;
  }
}

@include bp_down(large) {
  .c-slider__first-image {
    left: 80%;
  }
}

@include bp_down(medium) {
  .c-slider__first-image {
    left: 115%;
  }
}

.c-slider__second-image {
  position: absolute;
  top: 190px;
  left: 95%;
  bottom: 0;
  min-width: 400px;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);

  img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: auto;
    object-fit: cover;
    max-width: none;
  }
}

@include bp_down(large) {
  .c-slider__second-image {
    display: none;
  }
}

.c-slider__text {
  position: absolute;
  left: 0;
  top: 48.5%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
}

@include bp_down(large) {
  .c-slider__text {
    top: 52%;
  }
}

@include bp_down(medium) {
  .c-slider__text {
    max-width: 75%;
  }
}

.c-slider__heading {
  font-size: 1rem;
  text-transform: uppercase;
  padding: 0px 0px 15px 0px;
  margin: 0px;
}

.c-slider__subheading {
  padding: 0px 0px 38px 0px;
  margin: 0px;
}

.c-slider__description {
  padding: 0px;
  margin: 0px;
}

@include bp_down(medium) {
  .c-slider__description {
    padding-right: 30px;

    br {
      display: none;
    }
  }
}

.c-slider__btn {
  margin-top: 65px;
  padding: 20px 47px 19px 47px;
  font-size: 16px;

  .e-icons {
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    padding: 3px 0px 0px 2px;
  }
}

@include bp_down(medium) {
  .c-slider__btn {
    font-size: 14px;
    padding: 16px 40px 15px 40px;
    margin-top: 40px;
  }
}

.c-slider__text-right {
  position: absolute;
  bottom: 43px;
  right: 50px;
  text-transform: uppercase;
  z-index: 2;
}

@include bp_down(large) {
  .c-slider__text-right {
    bottom: 20px;
    right: 20px;
  }
}

@include bp_down(medium) {
  .c-slider__text-right {
    display: none;
  }
}

.c-slider__counter {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  font-size: 30px;
  line-height: 1;
}

@include bp_down(large) {
  .c-slider__counter {
    bottom: 20px;
    font-size: 20px;
  }
}

.c-slider {
  .splide__pagination {
    display: none;
  }
}