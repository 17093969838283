/* ==========================================================================
   #PAGINATION
   ========================================================================== */

.c-pagination {
  text-align: center;
  margin-top: 60px;
  margin-bottom: -60px;

  @include bp_down(medium) {
    margin-bottom: 0px;
    margin-top: 50px;
  }

  span {
    display: inline-block;
    vertical-align: top;
    min-width: 40px;
    line-height: 38px;
    border: 1px solid #e8e8e8;
    font-weight: 400;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    margin: 0 4px;
    padding: 0px 10px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.65);

    &.current {
      color: rgba(0, 0, 0, 0.85);
      border-color: #000;
    }

    a {
      display: block;
      position: relative;
      margin: -10px;
      padding: 10px;
    }
  }
}