/* ==========================================================================
   INUITCSS
   ========================================================================== */

/**
 * inuitcss, by @csswizardry
 *
 * github.com/inuitcss | inuitcss.com
 */

@import 'settings/_global.scss';

@import 'tools/_breakpoints.scss';

@import 'generic/_box-sizing.scss';
@import 'generic/_normalize.scss';
@import 'generic/_reset.scss';
@import 'generic/_shared.scss';

@import 'elements/_body.scss';
@import 'elements/_button.scss';
@import 'elements/_close_background.scss';
@import 'elements/_headings.scss';
@import 'elements/_hr.scss';
@import 'elements/_html.scss';
@import 'elements/_icons.scss';
@import 'elements/_images.scss';
@import 'elements/_inputs.scss';
@import 'elements/_links.scss';
@import 'elements/_lists.scss';
@import 'elements/_logo.scss';
@import 'elements/_product_grid.scss';
@import 'elements/_product_list.scss';
@import 'elements/_tables.scss';

@import 'vendor/_alertify.scss';
@import 'vendor/_slide_menu.scss';
@import 'vendor/_splide.scss';
@import 'vendor/_zoom.scss';

@import 'objects/_flex.scss';
@import 'objects/_layout.scss';
@import 'objects/_wrapper.scss';

@import 'components/_about.scss';
@import 'components/_account.scss';
@import 'components/_banners.scss';
@import 'components/_blog.scss';
@import 'components/_breadcrumb.scss';
@import 'components/_btn.scss';
@import 'components/_cart.scss';
@import 'components/_collection.scss';
@import 'components/_contact.scss';
@import 'components/_faq.scss';
@import 'components/_footer.scss';
@import 'components/_form_group.scss';
@import 'components/_header.scss';
@import 'components/_login.scss';
@import 'components/_mobile_bottom_bar.scss';
@import 'components/_nav.scss';
@import 'components/_nav_sidebar.scss';
@import 'components/_page.scss';
@import 'components/_pagination.scss';
@import 'components/_product_page.scss';
@import 'components/_products.scss';
@import 'components/_recently_viewed.scss';
@import 'components/_search.scss';
@import 'components/_slider.scss';
@import 'components/_wishlist.scss';

@import 'utilities/_align.scss';
@import 'utilities/_clearfix.scss';
@import 'utilities/_display.scss';
@import 'utilities/_hide.scss';
@import 'utilities/_margins.scss';
@import 'utilities/_sticky_top.scss';

@font-face {
  font-family: simple-line-icons;
  src: url(Simple-Line-Icons.eot?v=2.4.0);
  src: url(Simple-Line-Icons.eot?v=2.4.0#iefix) format('embedded-opentype'),
  url(Simple-Line-Icons.woff2?v=2.4.0) format('woff2'),
  url(Simple-Line-Icons.ttf?v=2.4.0) format('truetype'),
  url(Simple-Line-Icons.woff?v=2.4.0) format('woff'),
  url(Simple-Line-Icons.svg?v=2.4.0#simple-line-icons) format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}