/* ==========================================================================
   #WISHLIST
   ========================================================================== */

.c-header-wishlist {
  margin: 0px 0px 0px 24px;
  position: relative;

  .e-icons--icon-heart {
    line-height: 44px;
    font-size: 24px;
  }
}

.c-header-wishlist__counter {
  position: absolute;
  background: #dd2831;
  color: #fff;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  min-width: 18px;
  padding: 0 2px;
  line-height: 18px;
  font-size: 14px;
  top: 3px;
  right: auto;
  left: 10px;
  font-weight: 400;
  text-align: center;
}


/**
 * Wishlist page
 */
.c-wishlist {
  padding-top: 30px;
}