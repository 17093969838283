/* ==========================================================================
   #SEARCH
   ========================================================================== */

.c-header-search {
  padding: 0px 0px 0px 24px;

  .e-icons {
    font-size: 24px;
    line-height: 44px;
    cursor: pointer;
  }
}

.c-header-search__content {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 375px;
  max-width: 90%;
  background: #fff;
  z-index: 99;
  padding-bottom: 100px;
  overflow-y: scroll;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: $global-transition;
}

.c-header-search__content--active {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.e-close-background--search {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  display: block !important;
  z-index: 98;
}

.c-search__close {
  position: absolute;
  top: 20px;
  right: 20px;

  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.c-search {
  margin: 80px 0px 0px 0px;
  position: relative;
}

.c-search__input {
  padding: 0;
  height: 70px;
  font-size: 14px;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  padding: 17px 30px;
  width: 100%;
  background: #f5f5f5;
  border: none;
  outline: none;
}

.c-search__btn {
  background: none;
  border: none;
  padding: 0px;
  margin: 0px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  font-size: 25px;
  right: 25px;
  cursor: pointer;
}