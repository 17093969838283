/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  transition: $global-transition;
}

.c-header--background {
  background: #fff;
}

.index {
  .c-header {
    position: absolute;
  }
}

.c-header-desktop {
}

@include bp_down(xlarge) {
  .c-header-desktop {
    display: none;
  }
}

.c-header-desktop__right {
  margin-left: auto;
}


/* ==========================================================================
   #HEADER MOBILE
   ========================================================================== */

@include bp_down(xlarge) {
  .c-header {
    height: 50px;
  }

  .c-header__position {
    position: fixed !important;
    left: 0;
    top: 0;
    right: 0;
    background: #fff;
    height: 50px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  }

  .c-header-recently-viewed,
  .c-header-search,
  .c-header-account,
  .c-header-wishlist,
  .c-header-nav-sidebar {
    display: none;
  }
}

.c-header-mobile__menu {
  padding: 0px;
  cursor: pointer;
  display: none;

  @include bp_down(xlarge) {
    display: block;
  }

  > .e-icons {
    font-size: 20px;
    line-height: 50px;
  }

  > svg {
    width: 20px;
    height: 20px;
    display: none;
  }
}

@include bp_down(xlarge) {
  .c-header-cart {
    padding: 0px 0px 0px 24px;
    margin: 0px 0px 0px auto;
  }
}