/* ==========================================================================
   #PRODUCTS
   ========================================================================== */

.c-products {
  padding: 147px 0px 0px 0px;
}

@include bp_down(medium) {
  .c-products {
    padding: 70px 0px 0px 0px;
  }
}

.c-products__heading {
  padding: 0px 0px 14px 0px;
  margin: 0px;
}

.c-products__splide {
  > .splide__pagination {
    padding: 65px 0px 0px 0px;
    margin: 0px 0px -5px 0px;
    bottom: auto;
    left: auto;
    right: auto;
    position: relative;
    transform: none;
    width: 100%;
    display: block;
    text-align: center;

    li {
      display: inline-block;
      vertical-align: top;
      margin: 20px 8px 0px 8px;

      button {
        font-size: 0px;
        width: 6px;
        height: 6px;
        display: block;
        padding: 0px;
        margin: 0px;
        background: #d9d9d9;
        border: none;
        border-radius: 100px;
        -webkit-border-radius: 100px;
        -moz-border-radius: 100px;
        transition: all 0.4s ease-in-out;
        transform: none;
        opacity: 1;

        &.is-active {
          background: #262626;
        }
      }
    }
  }
}

@include bp_down(medium) {
  .c-products__splide {
    > .splide__pagination {
      display: none !important;
    }

    > .splide__track {
      > .splide__list {
        display: flex !important;
        flex-wrap: wrap !important;
        transform: none !important;
        width: auto !important;
        margin: 0px -10px 0px 0px !important;

        > .splide__slide {
          width: 50% !important;
          margin: 0px !important;
          padding-right: 10px !important;
        }
      }
    }
  }
}

.c-products__prev-slider {
  position: absolute;
  top: 45%;
  left: -90px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  z-index: 2;
  font-size: 32px;
  cursor: pointer;
  padding: 0px;
  margin: 0px;
  background: none;
  border: none;
  display: none !important;
}

@include bp(xlarge) {
  .c-products__prev-slider {
    display: block !important;
  }
}

.c-products__next-slider {
  position: absolute;
  top: 45%;
  right: -90px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  z-index: 2;
  font-size: 32px;
  cursor: pointer;
  padding: 0px;
  margin: 0px;
  background: none;
  border: none;
  display: none !important;
}

@include bp(xlarge) {
  .c-products__next-slider {
    display: block !important;
  }
}

/** 
 * Flash sale
 */

.c-products--flash-sale {
  .c-product-grid-meta__sold {
    display: block;
  }

  .c-products__splide {
    > .splide__pagination {
      display: none;
    }
  }
}

@include bp_down(medium) {
  .c-products__heading-countdown {
    flex-wrap: wrap;
  }
}

@include bp_down(medium) {
  .c-products__heading {
    width: 100%;
  }
}

.c-products__countdown {
  margin: 0px 0px 0px auto;
  padding: 0px 0px 0px 30px;
}

@include bp_down(medium) {
  .c-products__countdown {
    margin: 0px;
    padding: 0px;
    width: 100%;
  }
}

.c-countdown {
  display: flex;
  justify-content: center;
}

@include bp_down(medium) {
  .c-countdown {
    justify-content: left;
  }
}

.c-countdown__item {
  padding-left: 15px;
}

@include bp_down(medium) {
  .c-countdown__item {
    padding-left: 0px;
    padding-right: 15px;
  }
}

.c-products__left {
  margin: 0px 0px 0px 0px;
  padding: 27px 0px 0px 16px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.65);
}

@include bp_down(medium) {
  .c-products__left {
    padding-top: 14px;
    display: none;
  }
}

/**
 * Related products 
 */
.c-products--related-products {
  padding: 80px 0px 0px 0px;
  margin: 0px 0px -60px 0px;

  @include bp_down(medium) {
    margin-bottom: 0px;
    padding-top: 60px;
  }

  .c-products__heading {
    font-size: 24px;
    line-height: 32px;
  }

  .splide__pagination {
    display: none;
  }
}

.c-product {
  &.c-product--loading {
    .c-filter__loading {
      position: relative;
      z-index: 9;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        top: 180px;
        width: 50px;
        height: 50px;
        margin-left: -25px;
        background: url(../loader2.svg);
        background-size: cover;
      }
    }

    .c-product-page {
      opacity: 0.5;
    }
  }
}