/* ==========================================================================
   #INPUTS
   ========================================================================== */

input,
textarea,
select {
  display: block;
  width: 100%;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  padding: 8px 9px 7px 9px;
  outline: none;
}