/* ==========================================================================
   #HR
   ========================================================================== */

hr {
  display: block;
  background: none;
  border: none;
  border-top: 1px solid #e8e8e8;
  margin: 80px 0px;
}

@include bp_down(medium) {
  hr {
    margin: 40px 0px;
  }
}