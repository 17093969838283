/* ==========================================================================
   #MOBILE BOTTOM BAR
   ========================================================================== */

.c-mobile-bottom-bar {
  position: fixed;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 55px;
  background: #fff;
  box-shadow: 0 10px 40px rgba(0, 0, 0, .1);
  -webkit-box-shadow: 0 10px 40px rgba(0, 0, 0, .1);
  -moz-box-shadow: 0 10px 40px rgba(0, 0, 0, .1);
  z-index: 7;

  .o-flex {
    height: 55px;
  }
}

@include bp(xlarge) {
  .c-mobile-bottom-bar {
    display: none;
  }
}

.c-mobile-bottom-bar__item {
  display: block;
  width: 33.33%;
  text-align: center;
  padding: 0px 10px;

  a,
  div {
    display: block;
    position: relative;

    .c-header-wishlist__counter {
      top: -5px;
      left: 50%;
      background: #000;
      margin-left: 3px;
    }

    i {
      display: block;
      font-size: 22px;
      margin-bottom: 5px;
    }

    span {
      display: block;
      font-size: 10px;
      line-height: 1;
    }
  }
}