/* ==========================================================================
   #BUTTONS
   ========================================================================== */

.c-btn {
  display: inline-block;
  border: 1px solid $color-theme;
  //color: rgba(0,0,0,0.85);
  color: $color-theme;
  padding: 15px 40px 15px 40px;
  margin: 0px;
  font-size: 14px;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;
  position: relative;
  z-index: 2;
  overflow: hidden;
  transition: $global-transition;
  background: none;
  cursor: pointer;

  &:hover {
    color: #fff !important;
  }

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 100%;
    top: 0;
    left: -10%;
    z-index: -1;
    background: $color-theme;
    transition: all .8s cubic-bezier(.19, 1, .22, 1);
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
  }

  &:hover:before {
    width: 120%;
  }
}

.c-btn--black {
  background: $color-theme;
  color: #fff !important;
}

@include bp_down(medium) {
  .c-btn {
    padding: 12px 30px;
  }
}