/* ==========================================================================
   #PRODUCT LIST
   ========================================================================== */

.c-product-list {
  margin: 30px 0px 0px 0px;
  padding: 20px;
  border: 1px solid #e8e8e8;
}

.c-product-list__image {
  position: relative;
  min-width: 255px;
  width: 255px;
  max-width: 255px;

  .splide {
    a {
      display: block;

      img {
        display: block;
        width: 100%;
      }
    }

    .splide__pagination {
      display: none;
    }
  }
}

@include bp_down(medium) {
  .c-product-list__image {
    width: 40%;
    min-width: 40%;
    max-width: 40%;
  }
}

.c-product-list__image:hover .c-product-images__prev-slider,
.c-product-list__image:hover .c-product-images__next-slider {
  opacity: 1;
}

.c-product-list__tags {
  position: absolute;
  top: 24px;
  left: 24px;
  z-index: 2;
}

@include bp_down(medium) {
  .c-product-list__tags {
    left: 15px;
    top: 15px;
  }
}

.c-product-list__out-of-stock {
  padding: 0px 8px 0px 8px;
  margin: 0px 0px 8px 0px;
  background: #c3c3c3;
  color: #fff;
  font-size: 12px;
  line-height: 2;
  display: inline-block;
}

.c-product-list__sale {
  padding: 0px 8px 0px 8px;
  margin: 0px 0px 8px 0px;
  background: #dd2831;
  color: #fff;
  font-size: 12px;
  line-height: 2;
  display: inline-block;
}

.c-product-list__hot {
  padding: 0px 8px 0px 8px;
  margin: 0px 0px 8px 0px;
  background: #ffa200;
  color: #fff;
  font-size: 12px;
  line-height: 2;
  display: inline-block;
}

.c-product-list-meta {
  padding-left: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
}

@include bp_down(medium) {
  .c-product-list-meta {
    padding-left: 20px;
  }
}

.c-product-list-meta__heading {
  font-size: 18px;
  line-height: 22px;
  padding: 0px;
  margin: 0px;
}

.c-product-list-meta__price {
  font-size: 18px;
  padding: 12px 0px 0px 0px;
  margin: 0px;

  .c-price__new {
    color: #dd2831;
  }

  .c-price__old {
    text-decoration: line-through;
    color: rgba(0, 0, 0, 0.65);
    margin-left: 2px;
  }
}

.c-product-list-meta__description {
  padding: 20px 0px 0px 0px;
}