/* ==========================================================================
   #CLOSE BACKGROUND
   ========================================================================== */

.e-close-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .7);
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
  -webkit-transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
  -moz-transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
  z-index: 8;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.e-close-background--active {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

@include bp_down(xlarge) {
  .e-close-background {
    display: none !important;
  }
}