/* ==========================================================================
   #HEADINGS
   ========================================================================== */

h1 {
  font-size: 3.5rem;
  line-height: 1.27;
  font-weight: 400;
}

h2 {
  font-size: 3.15rem;
  line-height: 1.27;
  font-weight: 400;
}

h3 {
  font-size: 3.15rem;
  line-height: 1.27;
  font-weight: 400;
}

h4 {
  font-size: 2.5rem;
  line-height: 1.27;
  font-weight: 400;
}

h5 {
  font-size: 2rem;
  line-height: 1.27;
  font-weight: 400;
}

h6 {
  font-size: 1.5rem;
  line-height: 1.27;
  font-weight: 400;
}