/* ==========================================================================
   #PRODUCT GRID
   ========================================================================== */

.c-product-grid {
  margin: 30px 0px 0px 0px;
}

.c-product-grid__image {
  position: relative;

  img {
    display: block;
    width: 100%;
  }

  .splide {
    a {
      display: block;

      img {
        display: block;
        width: 100%;
      }
    }

    .splide__pagination {
      display: none;
    }
  }
}

.c-product-images__prev-slider {
  position: absolute;
  top: 50%;
  left: 17px !important;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  z-index: 2;
  font-size: 21px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  opacity: 0;
  padding: 0px;
  margin: 0px;
  background: none;
  border: none;
  @include bp(large) {
    display: block;
  }
}

.c-product-images__next-slider {
  position: absolute;
  top: 50%;
  right: 17px !important;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  z-index: 2;
  font-size: 21px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  display: none;
  opacity: 0;
  padding: 0px;
  margin: 0px;
  background: none;
  border: none;
  @include bp(large) {
    display: block;
  }
}

.c-product-grid__image:hover .c-product-images__prev-slider,
.c-product-grid__image:hover .c-product-images__next-slider {
  opacity: 1;
}

.c-product-grid__tags {
  position: absolute;
  top: 24px;
  left: 24px;
  z-index: 2;
}

@include bp_down(medium) {
  .c-product-grid__tags {
    left: 15px;
    top: 15px;
  }
}

.c-product-grid__out-of-stock {
  padding: 0px 8px 0px 8px;
  margin: 0px 0px 8px 0px;
  background: #c3c3c3;
  color: #fff;
  font-size: 12px;
  line-height: 2;
  display: inline-block;
}

.c-product-grid__weight {
  padding: 0px 8px 0px 8px;
  margin: 0px 0px 8px 0px;
  background: #d0cf7b;
  color: #000000;
  font-size: 12px;
  line-height: 2;
  display: inline-block;
}

.c-product-grid__sale {
  padding: 0px 8px 0px 8px;
  margin: 0px 0px 8px 0px;
  background: #dd2831;
  color: #fff;
  font-size: 12px;
  line-height: 2;
  display: inline-block;
}

.c-product-grid__hot {
  padding: 0px 8px 0px 8px;
  margin: 0px 0px 8px 0px;
  background: #ffa200;
  color: #fff;
  font-size: 12px;
  line-height: 2;
  display: inline-block;
}

.c-product-grid-meta {
  padding: 17px 0px 0px 0px;
}

.c-product-grid-meta__heading {
  font-size: 14px;
  line-height: 22px;
  padding: 0px;
  margin: 0px;
}

.c-product-grid-meta__price {
  font-size: 16px;
  padding: 12px 0px 0px 0px;
  margin: 0px;

  .c-price__new {
    color: #dd2831;
  }

  .c-price__old {
    text-decoration: line-through;
    color: rgba(0, 0, 0, 0.65);
    margin-left: 2px;
  }
}

.c-product-grid-meta__sold {
  margin: 20px 0px 0px 0px;
  padding: 0px;
  display: none;

  .c-sold__bar {
    height: 6px;
    background: #f5f5f5;
    padding: 0px;
    margin: 0px;

    span {
      display: block;
      height: 6px;
      background: #dd2831;
    }
  }

  .c-sold__text {
    margin: 0px;
    padding: 10px 0px 0px 0px;
    color: rgba(0, 0, 0, 0.65);

    span {
      padding-left: 2px;
      color: rgba(0, 0, 0, 0.85);
    }
  }
}