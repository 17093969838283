/* ==========================================================================
   #LOGIN
   ========================================================================== */

.c-login {
  padding: 30px 0px 0px 0px;
}

.c-login {
  &.c-login--loading {
    .c-filter__loading {
      position: relative;
      z-index: 9;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        top: 180px;
        width: 50px;
        height: 50px;
        margin-left: -25px;
        background: url(../loader2.svg);
        background-size: cover;
      }
    }

    .o-wrapper {
      opacity: 0.5;
    }
  }
}

@include bp_down(medium) {
  .c-login {
    padding-top: 10px;
  }
}

.c-login__box {
  padding-top: 50px;
  max-width: 500px;

  .c-btn {
    cursor: pointer;
    margin-top: 30px;
  }

  .o-layout--custom {
    margin: 0px -5px;

    .o-layout__item {
      padding: 0px 5px;
    }
  }
}

.c-login__box--register {
  margin-left: auto;
}

@include bp_down(medium) {
  .c-login__box {
    padding-top: 35px;
    max-width: 100%;
  }
}

.c-box__forgot-password {
  text-align: right;
}

.c-account-field {
  margin-left: 20px;
}

.c-account-div {
  margin-top: 20px;
  margin-bottom: 20px;
}

.c-login {
  &.c-login--loading {
    .c-filter__loading {
      position: relative;
      z-index: 9;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        top: 180px;
        width: 50px;
        height: 50px;
        margin-left: -25px;
        background: url(../loader2.svg);
        background-size: cover;
      }
    }

    .o-wrapper {
      opacity: 0.5;
    }
  }
}