/* ==========================================================================
   #PAGE
   ========================================================================== */

html {
  background: $color-bg;
  color: $color-text;
  font-family: $font-sans-serif;
  font-size: 0.6rem; /* [1] */
  /* 12px */
  line-height: 1.75; /* [1] */
  min-height: 100%; /* [3] */
  overflow-y: scroll; /* [2] */
  padding: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;

  @include bp(medium) {
    font-size: 0.75em; /* 13px */
  }

  @include bp(large) {
    font-size: 0.8em; /* 13.5px */
  }

  @include bp(full) {
    font-size: 0.875em; /* 14px */
  }
}